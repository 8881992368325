import React, { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * Per the React docs, this is ONLY used when an ancestor provider is not found.
 */
const defaultState = {
  ready: false,
  loggedIn: false,
  setLoggedIn: () => {},
};

/**
 * The actual context singleton.
 * You can import this to use the 'useContext' hook with a parent provider.
 */
const AuthContext = React.createContext(defaultState);

/**
 * The standard context provider you can use to wrap around children.
 * Children of this node can use the 'useContext' React hook to access the value.
 */
const AuthContextProvider = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [providerValue, setProviderValue] = useState({
    loggedIn,
    setLoggedIn,
  });

  // componentDidMount
  useEffect(() => {
    axios
      .get('/app/auth/user')
      .then((response) => {
        setLoggedIn(
          response.data.isAnonymous !== undefined
            ? !response.data.isAnonymous
            : false
        );
      })
      .catch((err) => {
        // Do nothing, this will happen often if the user is not logged in.
      })
      .then(() => {
        setReady(true);
      });
  }, []);

  // Update AuthContext Value
  useEffect(() => {
    setProviderValue({
      ready,
      loggedIn,
      setLoggedIn,
    });
  }, [loggedIn, ready]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export { AuthContextProvider };

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/KCrisanto/Documents/trane-sustainable-future-sso/frontend/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd402a40c94c4fb070847e8331d20eee"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"partnershipId":"848b15dc-6c9c-4872-97c0-c20970d56f41","extraDevHostnames":[],"extraStageHostnames":[],"extraProdHostnames":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

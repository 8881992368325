/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { AuthContextProvider } from './src/context/authContext';
import 'normalize.css';
import './src/styles/styles.scss';
import PageWrapper from './PageWrapper.js';

export const wrapPageElement = ({ element }) => {
  return (
    <AuthContextProvider>
      <PageWrapper>{element}</PageWrapper>
    </AuthContextProvider>
  );
};
